export const RETURN_SEARCH_PARAM_KEY = 'returnUrl'
const NO_RETURN_PAGES_PATH = ['/logout', '/login', '/logout-restaurant']

export function navigateToWithReturnUrl(pathname = '/') {
  const currentUrl = new URL(window.location)
  const {
    origin,
    pathname: currentPathname,
    search: currentSearch,
    searchParams,
  } = currentUrl

  const navigateToUrl = new URL(origin + pathname)
  const { pathname: navigateToPathname } = navigateToUrl

  if (currentPathname === navigateToPathname) return

  const isReturnSearchParamNeeded =
    !NO_RETURN_PAGES_PATH.includes(currentPathname)

  const redirectUrlParam =
    searchParams.get(RETURN_SEARCH_PARAM_KEY) ||
    `${currentPathname}${currentSearch}` ||
    '/'

  if (isReturnSearchParamNeeded) {
    navigateToUrl.searchParams.set(
      RETURN_SEARCH_PARAM_KEY,
      `${redirectUrlParam}`
    )
  }

  window.history.pushState('', null, navigateToUrl)
}

export function navigateToByReturnUrl(fromPathname) {
  const currentUrl = new URL(window.location)
  const { origin } = currentUrl
  const fromUrl = new URL(fromPathname, origin)
  if (currentUrl.pathname !== fromUrl.pathname) return

  const returnSearchParam = currentUrl.searchParams.get(RETURN_SEARCH_PARAM_KEY)

  const useReturnSearchParam =
    returnSearchParam && !NO_RETURN_PAGES_PATH.includes(returnSearchParam)

  const navigateToPathname = useReturnSearchParam ? returnSearchParam : '/'
  const navigateToUrl = new URL(origin + navigateToPathname)
  window.history.pushState('', null, navigateToUrl)
}
