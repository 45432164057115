import './set-public-path'
import { makeAuthService } from './services/authentication'
import { tokenTypesConfig } from './utils/tokenTypeConfig'

let auth = null

export const init = async () => {
  auth = makeAuthService()
  await auth.getCurrentUser()
}

export const forget = () => auth?.forget()
export const logout = (config) => auth?.logout(config)

export const getCurrentUser = async (params) =>
  await auth?.getCurrentUser(params)
export const getUserInfo = () => auth?.userService.user
export const fetchCurrentUser = async (params) =>
  await auth?.fetchCurrentUser(params)
export const subscribeToUserUpdates = (handler) =>
  auth?.subscribeToUserUpdates(handler)

export const getRestaurantId = () => auth?.getRestaurantId()
export const fetchUserRestaurantsList = () => auth?.fetchUserRestaurantsList()
export const selectUserRestaurant = (id) => auth?.selectUserRestaurant(id)

export const authenticate = () => auth?.authenticate()
export const isAuthorized = () => auth?.isAuthorized()
export const createMfaActivationUrl = (params) =>
  auth?.createMfaActivationUrl(params)

export const checkAndAuthorizeUser = async () => {
  if (auth?.isAuthorized()) return true
  auth
    .checkAuth()
    .then(() => true)
    .catch(async () => {
      await auth?.authenticate()
      return false
    })
}

export const getValidToken = async (params) => await auth?.getValidToken(params)
export { tokenTypesConfig }

export const acceptTermsAndConditions = async () =>
  await auth?.acceptTermsAndConditions()

export const beforeRouteEnterHook = async (to, from, next) => {
  const ALLOW_ANONYMOUS_META = 'allowAnonymous'
  const isAnonymousRoute =
    ALLOW_ANONYMOUS_META in to.meta
      ? to.meta[ALLOW_ANONYMOUS_META]
      : to.matched.some((m) => m.meta[ALLOW_ANONYMOUS_META])

  if (!isAnonymousRoute) {
    const isAuthorized = await checkAndAuthorizeUser()
    if (isAuthorized) next()
  } else {
    next()
  }
}
