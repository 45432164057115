import { hasImpersonationParams } from './impersonation'
import { getDecodedKeycloakToken } from './keycloakDataStorage'
import { IMPERSONATED_USER_TYPE } from '../constants'
import { RETURN_SEARCH_PARAM_KEY } from './navigateTo'

export const IMPERSONATED_USER_AUTHENTICATION_FLOW =
  'keycloak_impersonated_user'
export const IMPERSONATED_USER_AUTH_IN_PROGRESS =
  'keycloak_impersonated_user_auth_in_progress'

export function setImpersonatedUserAuthInProgress() {
  sessionStorage.setItem(IMPERSONATED_USER_AUTH_IN_PROGRESS, 'true')
}
export function isImpersonatedUserAuthInProgress() {
  return sessionStorage.getItem(IMPERSONATED_USER_AUTH_IN_PROGRESS)
}
export function removeImpersonatedUserAuthInProgress() {
  sessionStorage.removeItem(IMPERSONATED_USER_AUTH_IN_PROGRESS)
}

export function getShouldSkipChooseBusiness() {
  const currentUrl = new URL(location.href)
  const href =
    currentUrl.searchParams.get(RETURN_SEARCH_PARAM_KEY) || currentUrl.href
  const url = new URL(href, currentUrl.origin)
  return url.searchParams.get('choose_business') === 'false'
}

export function isImpersonatedUserAuthenticationFlow() {
  const token = getDecodedKeycloakToken()
  const isImpersonatedUserTokenStored = token?.atyp === IMPERSONATED_USER_TYPE

  return (
    isImpersonatedUserTokenStored ||
    hasImpersonationParams() ||
    isImpersonatedUserAuthInProgress()
  )
}
