import { TENANT } from '../constants'

export const getImpersonationParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const hr = urlParams.get('hr')
  const restaurantId = urlParams.get('restaurantid')
  return { hr, restaurantId }
}

export const appendImpersonationParams = (originalUrl, restaurantId) => {
  const url = new URL(originalUrl)
  url.searchParams.append('kc_idp_hint', 'okta')
  url.searchParams.append('restaurant_id', restaurantId)
  url.searchParams.append('tenant', TENANT)
  return url
}

export const hasImpersonationParams = () => {
  const { hr, restaurantId } = getImpersonationParams()
  return hr && restaurantId
}
