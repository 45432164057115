export async function fetchWithToken({
  host = '',
  url = '',
  token = '',
  params = {},
} = {}) {
  if (!token) {
    throw new Error(`Failed to fetch ${url} - the token does not exist.`)
  }
  const headers = { ...params.headers, Authorization: `Bearer ${token}` }
  const response = await fetch(`${host}${url}`, {
    ...params,
    headers,
  })
  if (!response.ok) {
    throw new Error(
      `Failed to fetch ${url}. Server responded with ${response.status} - ${response.statusText}`
    )
  }
  return response
}
