const TOKEN_TYPES = {
  keycloak: 'keycloak',
  exchanged: 'exchanged',
  connect: 'connect',
}

export const tokenTypesConfig = {
  list: TOKEN_TYPES,
  default: TOKEN_TYPES.exchanged,
}
