export default class Queue {
  static queue = []
  static workingOnPromise = false
  static stop = false

  static enqueue(promise) {
    return new Promise((resolve, reject) => {
      Queue.queue.push({
        promise,
        resolve,
        reject,
      })
      Queue.dequeue()
    })
  }

  static dequeue() {
    if (this.workingOnPromise) return false

    if (this.stop) {
      this.queue = []
      this.stop = false
      return
    }

    const item = this.queue.shift()

    if (!item) return false

    this.workingOnPromise = true
    item
      .promise()
      .then((value) => {
        this.workingOnPromise = false
        item.resolve(value)
        this.dequeue()
      })
      .catch((err) => {
        this.workingOnPromise = false
        item.reject(err)
        this.dequeue()
      })
    return true
  }

  static clear() {
    Queue.queue = []
  }
}
