import { jwtDecode } from 'jwt-decode'

const KEYCLOAK_DATA_STORAGE_KEY = 'je.pcweb.kc_token'

export function setKeycloakData({ token, refreshToken, idToken, timeSkew }) {
  const keycloakData = { token, refreshToken, idToken, timeSkew }

  localStorage.setItem(KEYCLOAK_DATA_STORAGE_KEY, JSON.stringify(keycloakData))
}

export function getKeycloakData() {
  try {
    const keycloakDataRaw = localStorage.getItem(KEYCLOAK_DATA_STORAGE_KEY)
    if (!keycloakDataRaw) return {}
    return JSON.parse(keycloakDataRaw)
  } catch (e) {
    return {}
  }
}

export function getKeycloakDataByType(atyp) {
  const token = getDecodedKeycloakToken()
  return token?.atyp === atyp ? getKeycloakData() : {}
}

export function getDecodedKeycloakToken() {
  try {
    const { token } = getKeycloakData()
    if (!token) return

    const decodedToken = jwtDecode(token)
    return decodedToken
  } catch (e) {
    return
  }
}

export function clearKeycloakData() {
  localStorage.removeItem(KEYCLOAK_DATA_STORAGE_KEY)
}
