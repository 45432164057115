import { isImpersonatedUserAuthenticationFlow } from '../../utils/authentication-flow'
import { KEYCLOAK_CONFIG, KEYCLOAK_IMPERSONATION_CONFIG } from '../../constants'

import GlobalAuthService from './global-auth'
import KeycloakAuthService from './keycloak-auth'
import UserService from '../user'

export const makeAuthService = () => {
  const authService = new KeycloakAuthService(getKeycloakConfig())
  const userService = new UserService(authService)

  return new GlobalAuthService(authService, userService)
}

const getKeycloakConfig = () => {
  const isImpersonatedUser = isImpersonatedUserAuthenticationFlow()
  return isImpersonatedUser ? KEYCLOAK_IMPERSONATION_CONFIG : KEYCLOAK_CONFIG
}
